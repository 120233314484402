import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useCookies } from 'vue3-cookies'
import VueGtag from 'vue-gtag-next'
import mitt from 'mitt'
import App from './App.vue'
import router from './router'
import { useFlowControlsStore } from '@/stores/flowControls'
import { requestIdleCallback } from '@/plugins/global'
import { stanAnalytics } from '@/plugins/stan-analytics'
import { configSettings } from './plugins/config-settings'
import axios from 'axios'
import '@/assets/styles/variables.scss'
import TopNavigation from './components/TopNavigation'
import FooterNavigation from './components/FooterNavigation'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(router)
const emitter = mitt()
app.config.globalProperties.$emitter = emitter
app.provide('configSettings', configSettings)
app.provide('_stan_app_emitter_', emitter)

app.component('TopNavigation', TopNavigation)
app.component('FooterNavigation', FooterNavigation)

const { cookies } = useCookies()
app.provide('cookies', cookies)
app.provide('stanAnalytics', stanAnalytics)

const fcStore = useFlowControlsStore()
fcStore.$reset()
fcStore.$subscribe(mutation => {
	if ('fcpComplete' in mutation.payload && mutation.payload.fcpComplete && !fcStore.vueGtagInstall) {
		requestIdleCallback(() => {
			app.use(VueGtag, {
				property: {
					id: configSettings.nodeEnvironment === 'production' ? 'G-FPXVPVPGVK' : 'Dummy', // a dummy id is needed for the debugging
					// id: 'G-FPXVPVPGVK'
				},
				appName: 'Stan Landing',
				params: { send_page_view: false },
				useDebugger: configSettings.nodeEnvironment !== 'production', // enable debugging for non-prod environment
			})
			fcStore.$patch({ vueGtagInstall: true })
		})
	}
})

axios.get('v1/get-ip').then(response => {
	fcStore.$patch({ ipAddress: response.data.ip })
})

app.mount('#app')
