// import { event as gTagEvent } from 'vue-gtag-next'
import { getFlags } from './feature-flags'
import Cookies from 'js-cookie'
import { getSessionUUID, getVisitorID, getCookieUUID } from './global'
const apiUrl = process.env.VUE_APP_ANALYTICS_API

function trigger(eventName, options) {
	const payload = {}
	payload.n = eventName
	payload.u = window.location.href
	payload.r = options.referrer || window.document.referrer || null
	payload.w = window.innerWidth
	if (options && options.meta) {
		payload.m = options.meta
	}

	if (!('m' in payload)) {
		payload.m = {}
	}
	payload.m.platform = {
		oscpu: navigator.oscpu,
		vendor: navigator.vendor,
		platform: navigator.platform,
		cpuClass: navigator.cpuClass,
		appName: navigator.appName,
		appVersion: navigator.appVersion,
		codeName: navigator.appCodeName,
		userAgent: navigator.userAgent,
	}

	if (options && options.props) {
		payload.p = options.props
	}

	const request = new XMLHttpRequest()
	request.open('POST', apiUrl, true)
	request.setRequestHeader('Content-Type', 'application/json')
	request.send(JSON.stringify(payload))

	// google analytics
	const flattenOptios = { ...payload.m, ...payload.p }
	Object.keys(flattenOptios).forEach(v => {
		if (v.includes('-')) {
			flattenOptios[v.replace('-', '_')] = flattenOptios[v]
			delete flattenOptios[v]
		}
	})
	// gTagEvent(eventName, flattenOptios)

	request.onreadystatechange = function () {
		if (request.readyState === 4) {
			options && options.callback && options.callback()
		}
	}
}
export const stanAnalytics = async (name, opts = {}) => {
	const meta = opts.meta || {}
	const props = opts.props || {}
	// Plugin default metadata
	meta.sessionUUID = getSessionUUID()
	meta.cookieUUID = getCookieUUID()

	// May or may not be used
	meta.visitorId = await getVisitorID()

	props.experiments = getFlags()
	const utmSource = Cookies.get('utm_source')
	const utmMedium = Cookies.get('utm_medium')
	const utmCampaign = Cookies.get('utm_campaign')

	if (utmSource) {
		props.utmSource = utmSource
	}
	if (utmMedium) {
		props.utmMedium = utmMedium
	}
	if (utmCampaign) {
		props.utmCampaign = utmCampaign
	}

	// Disabled Fingerprint until figure out the payment and plan details
	// meta.visitorId = await getVisitorID()
	// eslint-disable-next-line no-undef
	return trigger(name, { meta, props })
}
