import { defineStore } from 'pinia'
import axios from 'axios'

export const useLandingPage = defineStore('auth', {
	state: () => ({
		_referrerUserName: '',
		_trialDays: '',
		_referrer: {},
		_storeData: {},
		_customReferralPage: false,
	}),
	getters: {
		referrer(state) {
			return state._referrer
		},
		referrerFirstName(state) {
			return this.hasReferrer ? state._referrer.user?.full_name?.split(' ')?.[0] : ''
		},
		referrerFullName(state) {
			return this.hasReferrer ? state._referrer?.user?.full_name : ''
		},
		hasReferrer: state => state._customReferralPage,
		trialDays: state => state._trialDays,
	},
	actions: {
		async _initializeReferrer() {
			const urlString = window.location.href
			const url = new URL(urlString)
			this._referrerUserName = url.searchParams.get('ref') || ''
			//TODO: hardcoded case
			if (this._referrerUserName === 'justinwelsh') {
				this._justinWelshProfile()
			} else {
				this.hasReferrer && (await this._fetchReferrer())
			}
		},
		_initializeTrialDays() {
			const urlString = window.location.href
			const url = new URL(urlString)
			if (!this._trialDays?.length) this._trialDays = url.searchParams.get('trialDays') || ''
		},
		async initialize() {
			await this._initializeReferrer()
			this.hasReferrer ? this._initializeTrialDays() : (this._trialDays = '14')
		},
		_justinWelshProfile() {
			this._referrer = {
				user: {
					full_name: 'Justin Welsh',
				},
				heroHeader: 'Your All-in-One Solopreneur Store',
				heroSubHeader: 'The Saturday Solopreneur community gets their first month free when joining Stan. Start your business today.',
				referralComment:
					// eslint-disable-next-line
					"“I've tested a lot of online platforms. Stan blows me away. It's by far the easiest, fastest, and most affordable choice for anyone starting out as a solopreneur.”",
				headshot: '/images/home/v6/justin-welsh-headshot.png',
				phoneImage: '/images/home/v6/justin-welsh-phone-preview.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive ONLY to Justin's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Justin is the only person who has a free 30 days trial when joining Stan. This is the only time you'll get this offer.",
				stanXcreatorImage: '/images/home/v6/stanXjustin.png',
			}
			this._trialDays = '30'
			this._customReferralPage = true
		},
		_cleanProfile() {
			this._referrerUserName = ''
			this._referrer = {}
			this._trialDays = '14'
		},
		async _fetchReferrer() {
			try {
				const { data: response } = await axios.get(`v1/users/${this._referrerUserName}`)
				this._referrer.user = response.user
				this._storeData = response.storeData
			} catch (error) {
				this._cleanProfile()
			}
		},
	},
})
